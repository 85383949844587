@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
// endbower

@import 'owl/scss/owl.animate.scss';
@import 'owl/scss/owl.autoheight.scss';
@import 'owl/scss/owl.carousel.scss';
@import 'owl/scss/owl.core.scss';
@import 'owl/scss/owl.lazyload.scss';
@import 'owl/scss/owl.video.scss';
@import 'owl/scss/owl.theme.default.scss';

@import 'components/classic.scss';
@import 'components/classic.date.scss';

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/offers";
@import "layouts/tinymce";

// @media (max-width: 990px) {
//     .navbar-header {
//         float: none;
//     }
//     .navbar-toggle {
//         display: block;
//     }
//     .navbar-collapse {
//         border-top: 1px solid transparent;
//         box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
//     }
//     .navbar-collapse.collapse {
//         display: none;
// 				&.in{
// 					display: block;
// 				}
//     }
//     .navbar-nav {
//         float: none;
//         margin: 7.5px -15px;
//     }
//     .navbar-nav>li {
//         float: none;
//     }
//     .navbar-nav>li>a {
//         padding-top: 10px;
//         padding-bottom: 10px;
//     }
// }

@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
   .navbar-nav .open .dropdown-menu {
       position: static;
       float: none;
       width: auto;
       margin-top: 0;
       background-color: transparent;
       border: 0;
       -webkit-box-shadow: none;
       box-shadow: none;
    }

  #qw-main-filters .container-fluid {padding: 0px}
}
