.featured-info{
	position: relative;
	display: block;
	height: auto;
	width: 100%;
	padding: 30px 0 0;
	color: $brand-white;
	text-align: center;
	&:hover{
		color: $brand-white;
	}
	img{
		display: block;
	}
}


.featured-info-text{
	position: absolute;
	height: auto;
	width: 100%;
	top: 33%;
	left: 0;
	padding: 20px;
	background: rgba(0, 0, 0, .7);
	@media screen and (min-width: $screen-md-min){
		width: 64%;
		left: 18%;
		top: 40%;
		left: 5%;
	}
	h3{
		padding: 0;
		margin: 0;
		font-family: 'Open Sans';
		font-weight: 300;
		font-size: 23px;
		text-transform: uppercase;
		@media screen and (min-width: $screen-md-min){
			font-size: 30px;
		}
	}
	h4{
		padding: 0;
		margin-top: 10px;
		font-family: 'Open Sans';
		font-weight: 300;
		font-size: 14px;
		text-transform: uppercase;
	}
	@media screen and (min-width: $screen-md-min){
		width: 60%;
		left: 20%;
	}
}

.home-main{
	padding-bottom: 40px;
}

.home-events-wrap{
	padding: 40px 0;
	box-shadow: inset 0px 5px 10px 0px rgba(50, 50, 50, 0.4);
	.slide{
		padding: 10px;
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		a{
			color: $brand-black;
			&:hover{
				color: fade-out($brand-black, .2);
			}
			&:active{
				color: fade-out($brand-black, .2);
			}
			&:focus{
				color: fade-out($brand-black, .2);
			}
		}
		h3{
			margin: 10px 0 0;
			padding: 0 0 10px;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.2;
		}
		h4{
			margin: 0;
			padding: 0 0 8px;
			font-size: 14px;
			font-style: italic;
		}
		p{
		  font-size: 12px;
		}
	}
	.btn-home-events{
		height: auto;
		width: 236px;
		padding: 12px 18px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-size: 18px;
		color: $brand-black;
		background-color: $brand-white;
		border: 3px solid $brand-black;
	}
	.home-events-title-wrap{
		position: relative;
		width: 100%;
		@media screen and (min-width: $screen-md-min){
			position: relative;
			width: 52%;
			margin-left: auto;
			margin-right: auto;
		}
		@media screen and (min-width: $screen-lg-min){
			position: relative;
			width: 44%;
			margin-left: auto;
			margin-right: auto;
		}
		.home-events-title{
			position: relative;
			height: auto;
			width: 80%;
			@media screen and (min-width: $screen-md-min){
				text-align: center;
				width: 100%;
			}
		}
		.home-events-link{
			@extend .btn;
			position: absolute;
			right: 0;
			bottom: 0;
			font-style: italic;
			color: $brand-white;
			background-color: $brand-black;
			border-radius: 0;
			@media screen and (min-width: $screen-md-min){
				color: $brand-black;
				background-color: transparent;
				text-decoration: underline;
			}
		}
	}
}

.js-hide{
	display: block;
}

.owl-nav{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	.owl-prev{
		display: none;
		@media screen and (min-width: $screen-md-min){
			position: absolute;
			top: 0;
			left: -90px;
		}
	}
	.owl-next{
		display: none;
		@media screen and (min-width: $screen-md-min){
			position: absolute;
			top: 0;
			right: -90px;
		}
	}
}

.owl-theme .owl-nav [class*=owl-]{
	background-color: transparent;
	&:hover{
		background-color: transparent;
	}
	&:active{
		background-color: transparent;
	}
	&:focus{
		background-color: transparent;
	}
}

.home-slider-display{
	display: none;
	@media screen and (min-width: $screen-sm-min){
		display: block;
	}
}


.page-header {

	display: none

	}

.welcome{
	display: none;
	@media screen and (min-width: $screen-md-min){
		display: block;
	}
}
