
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	text-transform: uppercase;
}

h1 {font-size: 25px; font-family: "Futura"; letter-spacing: 2px}

.relative{
	position: relative;
}

.chevron-left{
	display: block;
	height: 200px;
	width: 70px;
	background: url(../images/chev-left.png) no-repeat;
	background-size: 100%;
	background-position: center center;
}

.chevron-right{
	display: block;
	height: 200px;
	width: 70px;
	background: url(../images/chev-right.png) no-repeat;
	background-size: 100%;
	background-position: center center;
}

.sidebar-primary{
	.main, .sidebar{
		margin-top: 60px;
	}
}
