// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


/// Booking

.home-slider-wrap{
	position: relative;
}
.booking-collapse{
	position: relative;
  z-index: 1400;
	width: 100%;

	@media screen and (min-width: $screen-md-min){
		position: absolute;
		top: -60px;
		right: 0;
		max-width: 400px;
	}
	@media screen and (min-width: $screen-lg-min){
		left: auto;
		right: 0;
	}
}

.booking-form{
	position: relative;
	height: auto;
	width: 100%;
	padding: 15px;
	z-index: 500;
	background-color: $brand-white;
	@media screen and (min-width: $screen-lg-min){
		max-width: 400px;
		z-index: 2000;
	}

	.form-control{
		border-radius: 0;
	}
}
.booking-form-safe-zone{
	height: 400px;
	width: 100%;
	max-width: 400px;
	z-index: 2000;
}
.picker-box{
	width: 100%;
	max-width: 380px;
	.picker{
		width: 370px;
		position: relative;
	}
	.picker__holder{
		position: relative;
		box-shadow: none;
		border: 0;
		&:focus{
			outline: -webkit-focus-ring-color auto 0;
		}
	}
	.picker.picker--opened.picker--focused{
		&:focus{
			outline: -webkit-focus-ring-color auto 0;
		}
	}
}

.booking-title{
	text-align: center;
	font-size: 20px;
}
.btn-booking{
	margin-top: 15px;
	width: 100%;
	border-radius: 0;
	background-color: $brand-primary;
	color: $brand-white;
}

.btn-primary.active{
	background-color: $brand-black;
}

.btn-primary, .btn-primary.active{
	&:hover{
		background-color: $brand-black;
	}
	&:active{
		background-color: $brand-black;
	}
	&:focus{
		background-color: $brand-black;
	}
	&:active:focus{
		background-color: $brand-black;
	}
	&:hover:focus{
		background-color: $brand-black;
	}
}

.hover-target{
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1090;
	&.active{
		display: block;
	}
}

.booking-form{
	.form-control{
		position: relative;
		z-index: 100;
	}
}

.cal-icon-in{
	position: absolute;
	top: 34px;
	right: 22px;
	z-index: 0;
}
