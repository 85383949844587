// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Colors
$brand-primary:         #A7C1B8;
$brand-secondary: 			#eeeeee;
$brand-white: 					#fff;
$brand-black:						#020202;
$brand-tiara: 					#bcc1bd;

$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, san-serif;
