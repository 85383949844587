.content-info{
	min-height: 400px;
	background-color: $brand-tiara;
}
.under-footer{
	min-height: 50px;
	background-color: $brand-black;
}

.search-box{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, .9);
	transition: all 1s ease;
	z-index: 1000;
	color: $brand-black;
	opacity: 0;
	&.active{
		opacity: 1;
	}
}

.input.search-input{
	position: absolute;
	padding: 20px 30px;
	width: 80%;
	top: 100px;;
	right: 10%;
	left: 10%;
	font-size: 26px;
	color: $brand-black;
	border: 1px solid $brand-black;
	background: transparent;
}

.search-close{
	position: absolute;
	top: 25px;
	right: 25px;
	font-size: 40px;
	color: $brand-black;
}

.footer-phone{
	float: left;
	margin-left: 0;
	@media (min-width: $screen-md-min) {
		float: left;
		margin-left: 30px;
	}
}

.content-info{
	.widget{
		a{
			color: $brand-black;
			text-decoration: underline;
			&:hover, &:active, &:focus{
				text-decoration: none;
			}
		}
	}
}
