
/* Offers  */

.offers-card {
	h2 {
		padding-top: 12px;
	}
	&.col-sm-4 {
		@media (min-width: $screen-md-min) {
			margin: 12px;
			width: 30% !important;
		}
	}
}


.offers-featured-img{
  margin-left: auto;
  margin-right: auto;
}

.offers-content{
	margin-bottom: 30px;
	h3{
		margin: 20px 0;
	}
}



.offers-card {
	min-height: 460px;
	padding: 0 10px;
	background-color: transparent;
	h2 {
		color: #333; font-size: 24px
	}
}

.offers-featured-img {
	width: 100%
}


.nav-offers{
	display: none;
	margin-bottom: 20px;
	li{
		border-right: 1px solid $brand-black;
		&:last-child{
			border-right: 0;
		}
	}
	&.nav-justified>li>a{
		color: $brand-black;
		padding-top: 0;
		padding-bottom: 0;
		border-bottom: 0px;
	}
	@media screen and (min-width: $screen-md-min){
		display: block;
	}
}
